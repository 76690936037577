<template>
  <v-card
    v-if="showMe"
    shaped
  >
    <v-card-title class="text-subtitle-1">
      Associazione account
    </v-card-title>
    <v-card-text>
      <v-row>
        <v-col
          cols="2"
        >
          <v-img
            :src="appInfo.logo"
            contain
          />
        </v-col>
        <v-col class="text-h6">
          <p class="text-h5 text-no-wrap">
            <span class="text-h5 text-no-wrap">{{ appInfo.username }}</span> su
            <span class="text-h5 text-no-wrap">{{ appInfo.name }}</span>
          </p>
        </v-col>
      </v-row>
      <v-row
        v-if="showPass"
      >
        <v-col cols="12">
          <form
            ref="form"
          >
            <v-text-field
              v-model="password"
              id="app_password"
              label="App password"
              name="app_password"
              prepend-icon="mdi-lock"
              :append-icon="show.app_password ? 'mdi-eye' : 'mdi-eye-off'"
              :type="show.app_password ? 'text' : 'password'"
              counter
              clearable
              @click:append="show.app_password = !show.app_password"
            />
          </form>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>
<script>
import { paramsFromNext } from '@/utils/misc'
global.pfn = paramsFromNext
export default {
  props: {
    regInfo: {
      type: Object,
      default: () => ({})
    }
  },
  data () {
    return {
      show: { app_password: false },
      password: null,
      appInfo: {
        id: null,
        name: '',
        description: '',
        username: null,
        logo: ''
      }
    }
  },
  computed: {
    showMe () {
      if (!this.appInfo.id) this.setAppInfo()
      return !!this.appInfo.id
    },
    showPass () {
      return this.showMe && this.appInfo.reg_type !== 'direct'
    }
  },
  methods: {
    getInfo () {
      return {
        app_id: this.appInfo.id,
        app_login: this.appInfo.username,
        app_password: this.password
      }
    },
    async setAppInfo () {
      if (this.regInfo.registerToken) {
        const res = await this.$http.get(`/accounts/app_info_bt/${this.regInfo.registerToken}/`)
        this.appInfo = res.data
      }
    }
  }
}
</script>
