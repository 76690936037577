<template>
  <DialogCard
    title="Gestione email"
    :actions="actions"
  >
    <v-form
      ref="form"
      v-model="valid"
    >
      <v-text-field
        ref="mail"
        v-model="email.email"
        :rules="[rules.email]"
      >
        <template v-slot:append>
          <v-tooltip
            left
          >
            <template #activator="{ on }">
              <v-icon
                :color="(email.verified && email.email === email.original) ? 'success' : 'warning'"
                v-text="(email.verified && email.email === email.original) ? 'mdi-check-circle' : 'mdi-help-circle'"
                v-on="on"
              />
            </template>
            <span>{{ (email.verified && email.email === email.original) ? 'Email verificata' : 'Email da verificare' }}</span>
          </v-tooltip>
        </template>
      </v-text-field>
    </v-form>
  </DialogCard>
</template>
<script>
import store from '@/store'
import messages from '@/utils/messages'
export default {
  data () {
    return {
      valid: null,
      email: {
        id: null,
        user_id: null,
        email: null,
        verified: false,
        primary: true,
        original: null
      },
      rules: {
        required: value => !!value || 'Required.',
        email: value => {
          if (!value) return true
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return (!!value && pattern.test(value)) || 'e-mail non valida.'
        }
      }
    }
  },
  computed: {
    actions (vm) {
      return {
        false: {
          text: 'Annulla',
          outlined: true,
          color: 'primary'
        },
        // reset: {
        //   outlined: true,
        //   text: 'Reinvia verifica',
        //   color: 'warning',
        //   handler () {
        //     vm.$http.post('/accounts/email/send_confirmation/').then(r => {
        //       if (r.data === 0) {
        //         vm.$dialog.notify.success('Tutte le email sono già verificate', { position: 'top-right' })
        //       } else {
        //         vm.$dialog.notify.info('Email di verifica inviata', { position: 'top-right' })
        //       }
        //     })
        //     // vm.$dialog.notify.info('Fatto', { position: 'top-right' })
        //     return false
        //   }
        // },
        true: {
          outlined: true,
          text: 'Salva',
          color: 'success',
          async handler () {
            const done = await vm.$http.patch('/accounts/sso_edit_email/', vm.email)
              .then(r => {
                if (r.data.length) {
                  store.commit('account/updateEmail', vm.email.email)
                  messages.info(r)
                  return true
                }
              })
              .catch(error => {
                messages.error(error)
                return false
              })
            return done
          }
        }
      }
    }
  },
  mounted () {
    global.editmail = this
    this.$http.get('/accounts/sso_edit_email/')
      .then(r => {
        this.email = { ...r.data }
        this.email.original = r.data.email
      })
  }
}
</script>
<style lang="scss">
  .deleted-email {
    /* text-decoration: line-through; */
    /* input { */
    /*   text-decoration: line-through; */
    /* } */
    &::before {
      border-top: 2px solid #da6758;
      opacity: 0.6 !important;
      content: " ";
      font-weight: 900;
      left: auto;
      position: absolute;
      top: 45%;
      width: 90%;
    }
  }
</style>
