<template>
  <v-container
    class="fill-height"
    fluid
  >
    <v-row
      align="center"
      justify="center"
    >
      <v-col
        cols="12"
        sm="8"
        md="4"
      >
        <v-card class="elevation-12">
          <v-toolbar
            color="primary"
            dark
            flat
          >
            <img
              src="/static/img/sso-1.png"
              style="height: 48px; width: auto;"
              position="left center"
              contain
            >
            <v-toolbar-title class="white--text ml-3">
              Verifica email nuovo account
            </v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <v-banner
              two-lines
              icon="mdi-email"
            >
              <p>
                Ti abbiamo inviato un' email di verifica.<br>
                Segui il link fornito per finalizzare il processo di registrazione.
              </p>
            </v-banner>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <router-link
              color="primary"
              to="/vaccount/login"
            >
              Torna alla pagina di login
            </router-link>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
export default {
  data () {
    return {
    }
  }
}
</script>
