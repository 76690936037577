<template>
  <v-card
    width="840px"
    class="mx-auto mt-5"
    title="Profilo"
  >
    <v-list
      rounded
      width="100%"
    >
      <v-list-item
        three-lines
      >
        <v-list-item-avatar
          :size="80"
        >
          <v-gravatar
            :email="showSsoAvatar ? user.email : ''"
            alt="Nobody"
            :size="80"
            default-img="mm"
          />
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title>Account</v-list-item-title>
          <v-list-item-subtitle>Modifica le informazioni del tuo account</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <template v-for="(item, index) in items">
        <v-list-item
          :key="item.title"
          @click="item.click"
        >
          <v-list-item-content>
            <v-list-item-subtitle v-html="item.title" />
          </v-list-item-content>
          <v-list-item-content>
            <v-list-item-title v-html="item.subtitle" />
          </v-list-item-content>
          <v-list-item-action
            v-if="item.action"
          >
            <v-switch
              v-if="item.type === 'bool'"
              v-model="item.value"
              :value="true"
            />
            <v-icon
              v-else
            >
              mdi-chevron-right
            </v-icon>
          </v-list-item-action>
        </v-list-item>
        <v-divider
          :key="index"
          inset
        />
      </template>
    </v-list>
    <v-card-actions>
      <v-spacer />
      <router-link
        :to="{ name: 'Dashboard' }"
      >
        <v-btn
          color="primary"
        >
          Chiudi
        </v-btn>
      </router-link>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import EditEmail from './EditEmail.vue'
import EditPhone from './EditPhone.vue'
import EditPassword from './EditPassword.vue'
export default {
  data () {
    return {}
  },
  computed: {
    ...mapState('account', ['user']),
    showSsoAvatar: {
      get () {
        return this.$store.state.account.user.show_sso_avatar === true
      },
      set (value) {
        this.updateShowSsoAvatar(value === true)
      }
    },
    showAppsAvatar: {
      get () {
        return this.$store.state.account.user.show_apps_avatar === true
      },
      set (value) {
        this.updateShowAppsAvatar(value === true)
      }
    },
    items () {
      return [
        {
          title: 'Modifica email',
          subtitle: this.user.email,
          action: true,
          click: () => this.$dialog.show(EditEmail)
        },
        {
          title: 'Modifica telefono',
          subtitle: this.user.phone,
          action: true,
          click: () => this.$dialog.show(EditPhone)
        },
        {
          title: 'Modifica password',
          subtitle: '********',
          action: true,
          click: () => this.$dialog.show(EditPassword)
        },
        {
          title: 'Mostra <b>gravatar</b> per l\'account SSO',
          subtitle: this.user.show_sso_avatar ? 'Si' : 'No',
          type: 'skip',
          value: () => this.showSsoAvatar ? 'Si' : 'No',
          click: this.toggleShowSsoAvatar
        },
        {
          title: 'Mostra <b>gravatar</b> per gli account di applicazione',
          subtitle: this.user.show_apps_avatar ? 'Si' : 'No',
          type: 'skip',
          value: () => this.showAppsAvatar ? 'Si' : 'No',
          click: this.toggleShowAppsAvatar
        }
      ]
    }
  },
  methods: {
    ...mapActions('account', ['updateShowSsoAvatar', 'updateShowAppsAvatar']),
    toggleShowSsoAvatar () {
      console.log('avatar', this.showSsoAvatar)
      this.showSsoAvatar = !this.showSsoAvatar
    },
    toggleShowAppsAvatar () {
      console.log('avatar', this.showAppsAvatar)
      this.showAppsAvatar = !this.showAppsAvatar
    }
  },
  mounted () {
    global.account = this
  }
}
</script>
