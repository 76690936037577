<template>
  <v-container
    class="fill-height main-view"
    fluid
  >
    <v-row
      align="center"
      justify="center"
    >
      <v-col
        cols="12"
        sm="8"
        md="6"
      >
        <v-card
          class="elevation-12 register-form"
        >
          <account-toolbar title="Registrati su EpSso" />
          <v-stepper
            v-model="step"
          >
            <v-stepper-header>
              <v-stepper-step
                v-if="showDisclaimer"
                :color="read ? 'success' : 'primary'"
                editable
                :complete="read"
                :step="1"
              >
                Avviso
              </v-stepper-step>

              <v-divider
                v-if="showDisclaimer"
              />

              <v-stepper-step
                editable
                :complete="registerOk"
                :color="registerOk ? 'success' : 'primary'"
                :step="2"
              >
                Registrazione
              </v-stepper-step>

              <v-divider />

              <v-stepper-step
                :step="3"
                :editable="registerOk"
              >
                Associazione utente
              </v-stepper-step>
            </v-stepper-header>
            <v-stepper-items
              class="pa-0"
            >
              <v-stepper-content
                :step="1"
              >
                <v-card
                  min-height="450px"
                >
                  <register-disclaimer
                    :disclaimer="appInfo.registerDisclaimer"
                  />
                </v-card>
                <p>
                  Se hai già un account valido <router-link :to="{ name: 'Login', query: $route.query }">
                    Accedi
                  </router-link>.
                </p>
                <v-btn
                  small
                  rounded
                  color="primary"
                  @click="step = 2; read = true"
                  class="float-right mx-1"
                >
                  Continua
                </v-btn>
              </v-stepper-content>
              <v-stepper-content :step="2">
                <v-card
                  min-height="450px"
                >
                  <register-register
                    ref="registerPart"
                    :register="register"
                    :register-info="appInfo.registerInfo"
                  />
                </v-card>
                <p>
                  Se hai già un account valido <router-link :to="{ name: 'Login', query: $route.query }">
                    Accedi
                  </router-link>.
                </p>
                <v-btn
                  small
                  rounded
                  color="primary"
                  @click="validateRegisteration"
                  class="float-right mx-1"
                >
                  Continua
                </v-btn>
              </v-stepper-content>
              <v-stepper-content :step="3">
                <v-card
                  min-height="450px"
                >
                  <register-add
                    ref="accountPart"
                    :register="register"
                    :app-info="appInfo"
                    :app-list="appList"
                    :add-account-info="appInfo.addAccountInfo"
                    @appChanged="appChanged"
                    @doRecovery="doRecovery"
                  />
                </v-card>
                <p>
                  Se hai già un account valido <router-link :to="{ name: 'Login', query: $route.query }">
                    Accedi
                  </router-link>.
                </p>
                <v-btn
                  small
                  rounded
                  color="primary"
                  @click="doSignUp"
                  class="float-right mx-1"
                >
                  Registrati
                </v-btn>
              </v-stepper-content>
            </v-stepper-items>
          </v-stepper>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex'
import isEmpty from 'lodash/isEmpty'
import { regInfoFromClientId } from '@/utils/misc.js'
import RecoveryAccount from './RecoveryAccount.vue'
import messages from '@/utils/messages.js'
export default {
  components: {
    registerDisclaimer: () => import('./RegisterDisclaimer.vue'),
    registerRegister: () => import('./RegisterRegister.vue'),
    registerAdd: () => import('./RegisterAdd.vue'),
    AccountToolbar: () => import('./AccountToolbar.vue')
  },
  data () {
    return {
      appList: [],
      read: false,
      registerOk: false,
      accountOk: false,
      appInfo: {
        id: null,
        name: '',
        description: '',
        logo: '',
        registerDisclaimer: '',
        registerInfo: '',
        addAccountInfo: '',
        regType: 'vcode',
        vcode: null,
        username: null
      },
      selectedApp: {
        id: null
      },
      step: '1',
      register: {
        app_login: null,
        app_password: null,
        auth_privacy: true,
        email: null,
        phone: null,
        password1: null,
        password2: null,
        username: null,
        validation_code: null
      }
    }
  },
  mounted () {
    global.rwiz = this
    this.getAppInfo()
    this.getApps()
  },
  computed: {
    showDisclaimer () {
      return !!this.appInfo.registerDisclaimer
    }
  },
  methods: {
    ...mapActions('account', ['registerUser']),
    async validateRegisteration () {
      this.registerOk = false
      if (this.$refs.registerPart.$refs.form.validate()) {
        this.register.app_id = this.appInfo.id || this.selectedApp.id
        await this.$http.post('/accounts/check_register_user/', { ...this.register, check_user_only: true })
          .then(res => {
            this.registerOk = true
            this.step = 3
          })
          .catch(error => {
            messages.error(messages.errorMerge(error.response.data))
            this.registerOk = false
            this.step = 2
          })
        // console.log('Response', res)
      }
    },
    async getApps () {
      const apps = await this.$http.get('/accounts/apps/account/list_apps/')
      this.appList = apps.data
    },
    async getAppInfo () {
      let app = {}
      if (this.$route.query.next) {
        app = await regInfoFromClientId(this.$route.query)
      }
      if (!isEmpty(app)) {
        this.appInfo = app
        this.register.app_login = this.$route.query.username || ''
      }
      if (!this.showDisclaimer) this.step = 2
    },
    appChanged (newApp) {
      this.selectedApp = newApp
    },
    doSignUp () {
      if (!this.$refs.registerPart.$refs.form.validate()) {
        this.registerOk = false
        this.step = 2
        return
      }
      if (!this.$refs.accountPart.$refs.form.validate()) {
        this.accountOk = false
        this.step = 3
        return
      }
      this.register.app_id = this.appInfo.id || this.selectedApp.id
      this.register.raise = true
      this.registerUser(this.register).catch(e => {
        const data = e.response.data
        this.registerOk = false
        this.step = 3
        for (const [key, value] of Object.entries(data)) {
          console.log('field', key, value)
        }
      })
    },
    doRecovery (app) {
      const localApp = app || this.selectedApp
      this.$dialog.show(
        RecoveryAccount,
        {
          appId: localApp.id,
          appName: localApp.name
        }
      )
    }
  }
}
</script>
<style lang="scss">
.register-form {
    z-index: 10;
  }
</style>
