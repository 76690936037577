<template>
  <DialogCard
    title="Richiesta convalida email"
    :actions="actions"
  >
    <v-form
      ref="form"
      v-model="valid"
    >
      <v-text-field
        v-model="email"
        label="Email"
        :rules="[rules.required]"
        prepend-icon="mdi-email"
        @keypress.enter.prevent="enterPressed"
        clearable
      />
    </v-form>
  </DialogCard>
</template>
<script>
import rules from '@/utils/rules.js'
import messages from '@/utils/messages'
export default {
  data () {
    return {
      rules,
      valid: null,
      email: null
    }
  },
  methods: {
    async enterPressed () {
      const done = await this.actions.true.handler()
      if (done) {
        this.$parent.$parent.$parent.close()
      }
    }
  },
  computed: {
    actions (vm) {
      return {
        false: {
          text: 'Annulla',
          flat: false,
          outlined: true,
          color: 'primary'
        },
        true: {
          text: 'Invia',
          flat: false,
          outlined: true,
          color: 'success',
          attrs: {
            default: true,
            id: 'clickinvia'
          },
          async handler (closeme) {
            let done = false
            if (vm.$refs.form.validate()) {
              await vm.$http.post('/accounts/email/send_confirmation/', { email: vm.email.trim() })
                .then(r => {
                  done = true
                  messages.info(r)
                })
                .catch(e => {
                  done = false
                  messages.error(e)
                })
            }
            if (done && closeme === true) this.close()
            return done
          }
        }
      }
    }
  }
}
</script>
<style lang="scss">
  .deleted-email {
    text-decoration: line-through;
  }
</style>
