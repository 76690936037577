<template>
  <v-container
    class="fill-height"
    fluid
  >
    <v-row
      align="center"
      justify="center"
    >
      <v-col
        cols="12"
        sm="8"
        md="4"
      >
        <v-card class="elevation-12 register-form">
          <v-toolbar
            color="primary"
            dark
            flat
          >
            <img
              src="/static/img/sso-1.png"
              style="height: 48px; width: auto;"
              position="left center"
              contain
            >
            <v-toolbar-title class="white--text ml-3">
              Password scaduta
            </v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <p>
              Reimposta la nuova password.
            </p>
            <v-form
              ref="form"
              v-model="valid"
            >
              <v-text-field
                v-model="username"
                tabindex="1"
                label="Nome utente"
                name="username"
                prepend-icon="mdi-account"
                type="text"
                :rules="[rules.required]"
              />
              <v-text-field
                v-model="password.old_password"
                tabindex="2"
                label="Password attuale"
                prepend-icon="mdi-lock"
                :rules="[rules.required]"
                :append-icon="show.old_password ? 'mdi-eye' : 'mdi-eye-off'"
                :type="show.old_password ? 'text' : 'password'"
                counter
                clearable
                @click:append="show.old_password = !show.old_password"
              />
              <v-text-field
                v-model="password.new_password1"
                tabindex="3"
                label="Nuova password"
                prepend-icon="mdi-lock"
                :rules="[rules.required]"
                :append-icon="show.new_password1 ? 'mdi-eye' : 'mdi-eye-off'"
                :type="show.new_password1 ? 'text' : 'password'"
                counter
                clearable
                @click:append="show.new_password1 = !show.new_password1"
              />
              <v-text-field
                v-model="password.new_password2"
                tabindex="4"
                label="Verifica password"
                prepend-icon="mdi-lock"
                :rules="[rules.required, rules.sameAs(password, 'new_password1', 'Nuova password')]"
                :append-icon="show.new_password2 ? 'mdi-eye' : 'mdi-eye-off'"
                :type="show.new_password2 ? 'text' : 'password'"
                counter
                clearable
                @click:append="show.new_password2 = !show.password2"
              />
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn
              color="primary"
              :to="{name: 'Login'}"
            >
              Annulla
            </v-btn>
            <v-btn
              color="primary"
              @click="doChange"
            >
              Reimposta
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import rules from '@/utils/rules.js'
import { mapActions } from 'vuex'
export default {
  props: {
    username: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      rules,
      valid: null,
      email: null,
      show: {
        old_password: false,
        new_password1: false,
        new_password2: false
      },
      password: {
        old_password: null,
        new_password1: null,
        new_password2: null
      }
    }
  },
  methods: {
    ...mapActions('account', ['changePassword']),
    doChange () {
      if (!this.$refs.form.validate()) return
      this.password.old_password = this.password.old_password.trim()
      this.password.new_password1 = this.password.new_password1.trim()
      this.password.new_password2 = this.password.new_password2.trim()
      this.password.username = this.username.trim()
      this.changePassword(this.password)
    }
  }
}
</script>
<style lang="scss">
.register-form {
  z-index: 10;
}
</style>
