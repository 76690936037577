<template>
  <v-container
    class="fill-height"
    fluid
  >
    <v-row
      align="center"
      justify="center"
    >
      <v-col
        cols="12"
        sm="8"
        md="4"
      >
        <v-card class="elevation-12 register-form">
          <account-toolbar title="Accedi a EpSso" />
          <v-form
            ref="form"
            v-model="valid"
          >
            <v-card-text>
              <div v-html="appInfo.loginDisclaimer" />
              <p>
                Se non hai ancora creato un account <router-link :to="{ path: registerUrl, query: $route.query }">
                  Registrati
                </router-link>.
              </p>
              <v-card
                shaped
              >
                <v-card-text>
                  <v-text-field
                    v-model="login.username"
                    label="Username o email"
                    name="login"
                    prepend-icon="mdi-account"
                    type="text"
                    :rules="[rules.required]"
                    @keyup.enter="doLogin"
                  />

                  <v-text-field
                    v-model="login.password"
                    id="password"
                    label="Password"
                    name="password"
                    prepend-icon="mdi-lock"
                    :append-icon="show.current ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="show.current ? 'text' : 'password'"
                    counter
                    clearable
                    :rules="[rules.required]"
                    @click:append="show.current = !show.current"
                    @keyup.enter="doLogin"
                  />
                </v-card-text>
              </v-card>
            </v-card-text>
            <v-card-text>
              <l-register-info
                ref="regInfo"
                :reg-info="nextParams"
              />
            </v-card-text>
            <v-card-actions>
              <v-btn
                small
                text
                color="primary"
                @click="showReset"
                style="text-transform:none !important;"
              >
                Recupera password
              </v-btn>
              <v-btn
                small
                text
                color="primary"
                @click="showValidation"
                style="text-transform:none !important;"
              >
                Validazione email
              </v-btn>
              <v-spacer />
              <v-btn
                color="primary"
                @click="doLogin"
              >
                Login
              </v-btn>
            </v-card-actions>
          </v-form>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex'
import rules from '@/utils/rules.js'
import { paramsFromNext, regInfoFromClientId } from '@/utils/misc.js'
import isEmpty from 'lodash/isEmpty'
import RegisterInfo from './RegisterInfo.vue'
// import AppSelector from '../Dashboard/AppSelector.vue'
import ResetPassword from './ResetPassword.vue'
import SendEmailValidation from './SendEmailValidation.vue'

export default {
  components: {
    // 'l-app-selector': AppSelector,
    'l-register-info': RegisterInfo,
    AccountToolbar: () => import('./AccountToolbar.vue')
  },
  data () {
    return {
      forceDashboard: false,
      appInfo: {
        id: null,
        name: '',
        description: '',
        logo: '',
        registerDisclaimer: '',
        registerInfo: '',
        addAccountInfo: '',
        regType: 'vcode',
        vcode: null,
        username: null
      },
      rules,
      valid: false,
      show: {
        current: false
      },
      login: {
        username: null,
        password: null
      }
    }
  },
  computed: {
    nextParams () {
      return paramsFromNext(this.$route.query)
    },
    registerApp: {
      get () {
        return this.appRegItem
      },
      set (app) {
        if (app && app.app && app.app.id) this.appRegItem = app
      }
    },
    registerUrl () {
      return this.appInfo.registerUrl || '/vaccount/registerwizard'
    }
  },
  mounted () {
    this.getAppInfo()
  },
  methods: {
    ...mapActions('account', ['loginUser']),
    async getAppInfo () {
      let app = {}
      if (this.$route.query.next) {
        app = await regInfoFromClientId(this.$route.query)
      }
      if (!isEmpty(app)) {
        this.appInfo = app
      }
      // if (!this.showDisclaimer) this.step = 2
    },
    showReset () {
      this.$dialog.show(ResetPassword)
    },
    showValidation () {
      this.$dialog.show(SendEmailValidation)
    },
    doLogin () {
      if (!this.$refs.form.validate()) return
      const clientId = this.nextParams?.params?.client_id || null
      this.loginUser({ force_dashboard: this.forceDashboard, client_id: clientId, ...this.login, ...this.$refs.regInfo.getInfo() })
    }
  }
}
</script>
<style lang="scss">
.register-form {
  z-index: 10;
}
</style>
