<template>
  <v-container
    class="fill-height"
    fluid
  >
    <v-row
      align="center"
      justify="center"
    >
      <v-col
        cols="12"
        sm="8"
        md="4"
      >
        <v-card class="elevation-12">
          <v-toolbar
            color="primary"
            dark
            flat
          >
            <img
              src="/static/img/sso-1.png"
              style="height: 48px; width: auto;"
              position="left center"
              contain
            >
            <v-toolbar-title class="white--text ml-3">
              Reimposta password
            </v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <v-form
              ref="form"
              v-model="valid"
            >
              <v-text-field
                v-if="isPhone"
                v-model="resetItem.sms_code"
                label="Codice verifica SMS"
                :rules="[rules.numeric]"
                tabindex="1"
                counter
                clearable
              />
              <v-text-field
                v-model="resetItem.new_password1"
                label="Nuova password"
                :rules="newRules"
                :append-icon="show.new_password1 ? 'mdi-eye' : 'mdi-eye-off'"
                :type="show.new_password1 ? 'text' : 'password'"
                tabindex="2"
                counter
                clearable
                @click:append="show.new_password1 = !show.new_password1"
              />
              <v-text-field
                v-model="resetItem.new_password2"
                label="Verifica password"
                :rules="[rules.required, rules.sameAs(resetItem, 'new_password1', 'Nuova password')]"
                :append-icon="show.new_password2 ? 'mdi-eye' : 'mdi-eye-off'"
                :type="show.new_password2 ? 'text' : 'password'"
                tabindex="3"
                counter
                clearable
                @click:append="show.new_password2 = !show.password2"
              />
            </v-form>
          </v-card-text>
          <v-card-actions>
            <router-link :to="{ name: 'Login', query: $route.query }">
              Annulla
            </router-link>
            <v-spacer />
            <v-btn
              color="primary"
              outlined
              @click="doReset"
              tabindex="3"
            >
              Reimposta
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import rules from '@/utils/rules.js'
import messages from '@/utils/messages.js'
// import store from '@/store'
export default {
  data () {
    return {
      resetItem: {
        sms_code: null,
        uid: this.$route.params.uid,
        token: this.$route.params.token,
        new_password1: null,
        new_password2: null
      },
      show: {
        new_password1: false,
        new_password2: false
      },
      rules,
      valid: null,
      email: null
    }
  },
  computed: {
    isPhone () {
      return this.resetItem.uid === 'phone'
    },
    newRules () {
      return [rules.required] //, rules.password, rules.sequenza(store.state.account.user, 'username')]
    }
  },
  methods: {
    async doReset () {
      let done = false
      if (this.$refs.form.validate()) {
        done = await this.$http.post('/rest-auth/password/reset/confirm/', this.resetItem)
          .then(r => {
            messages.info(r)
            this.$router.push({ name: 'Login' })
          })
          .catch(messages.error)
      }
      return done
    }
  }
}
</script>
