<template>
  <DialogCard
    title="Gestione password"
    :actions="actions"
  >
    <v-form
      ref="form"
      v-model="valid"
    >
      <v-text-field
        v-model="password.old_password"
        tabindex="1"
        label="Password attuale"
        :rules="[rules.required]"
        :append-icon="show.old_password ? 'mdi-eye' : 'mdi-eye-off'"
        :type="show.old_password ? 'text' : 'password'"
        counter
        clearable
        @click:append="show.old_password = !show.old_password"
      />
      <v-text-field
        v-model="password.new_password1"
        tabindex="2"
        label="Nuova password"
        :rules="newRules"
        :append-icon="show.new_password1 ? 'mdi-eye' : 'mdi-eye-off'"
        :type="show.new_password1 ? 'text' : 'password'"
        counter
        clearable
        @click:append="show.new_password1 = !show.new_password1"
      />
      <v-text-field
        v-model="password.new_password2"
        tabindex="3"
        label="Verifica password"
        :rules="[rules.required, rules.sameAs(password, 'new_password1', 'Nuova password')]"
        :append-icon="show.new_password2 ? 'mdi-eye' : 'mdi-eye-off'"
        :type="show.new_password2 ? 'text' : 'password'"
        counter
        clearable
        @click:append="show.new_password2 = !show.password2"
      />
    </v-form>
  </DialogCard>
</template>
<script>
import rules from '@/utils/rules.js'
import messages from '@/utils/messages.js'
// import store from '@/store'
export default {
  data () {
    return {
      rules,
      valid: null,
      email: null,
      show: {
        old_password: false,
        new_password1: false,
        new_password2: false
      },
      password: {
        old_password: null,
        new_password1: null,
        new_password2: null
      }
    }
  },
  computed: {
    newRules () {
      return [rules.required] //, rules.password, rules.sequenza(store.state.account.user, 'username')]
    },
    actions (vm) {
      return {
        false: {
          text: 'Annulla',
          flat: false,
          outlined: true,
          color: 'primary'
        },
        true: {
          text: 'Salva',
          flat: false,
          outlined: true,
          color: 'success',
          async handler () {
            let done = false
            if (vm.$refs.form.validate()) {
              done = await vm.$http.post('/rest-auth/password/change/', vm.password)
                .then(messages.info)
                .catch(messages.error)
            }
            return done
          }
        }
      }
    }
  }
}
</script>
