<template>
  <DialogCard
    :title="title"
    :actions="actions"
  >
    <v-form
      ref="form"
      v-model="valid"
    >
      <p>
        Salvando il numero verrà reinviato il codice di convalida.
      </p>
      <v-text-field
        v-if="askCode"
        v-model="newPhone.sms_code"
        label="Codice verifica SMS"
        :rules="[rules.required, rules.numeric]"
        prepend-icon="mdi-cellphone-lock"
        clearable
      />
      <vue-tel-input-vuetify
        ref="phone"
        tabindex="4"
        class="sso-tel-input"
        v-model="newPhone.phone"
        v-bind="phoneProps"
      >
        <template #prepend>
          <v-icon
            ref="phoneIcon"
            v-text="'mdi-cellphone-android'"
            :color="phoneProps.valid ? '' : 'error'"
          />
        </template>
        <template #append>
          <v-tooltip
            left
          >
            <template v-slot:activator="{ on }">
              <v-icon
                class="ml-2 mr-3"
                v-on="on"
                v-text="newPhone.verified ? 'mdi-check-circle' : 'mdi-help-circle'"
                :color="newPhone.verified ? 'success' : 'warning'"
              />
            </template>
            <span>{{ newPhone.verified ? 'Telefono verificato' : 'Telefono da verificare' }}</span>
          </v-tooltip>
        </template>
      </vue-tel-input-vuetify>
    </v-form>
  </DialogCard>
</template>
<script>
import { mapState } from 'vuex'
import store from '@/store'
import rules from '@/utils/rules.js'
import messages from '@/utils/messages'
export default {
  data () {
    return {
      phoneProps: {
        label: 'Telefono',
        name: 'phone',
        clearable: true,
        onlyCountries: ['IT'],
        defaultCountry: 'IT',
        validCharactersOnly: true,
        rules: [this.checkPhone],
        valid: true,
        mode: 'national',
        dropdownOptions: {
          disabledDialCode: true
        },
        inputOptions: {
          showDialCode: false
        }
      },
      valid: null,
      askCode: false,
      rules,
      newPhone: {
        phone: '',
        varified: false,
        token: '',
        sms_code: ''
      }
    }
  },
  computed: {
    ...mapState('account', ['user']),
    title () {
      if (this.askCode) return 'Inserire il codice di covalida'
      return 'Gestione telefono'
    },
    actions (vm) {
      return {
        false: {
          text: 'Annulla',
          outlined: true,
          color: 'primary'
        },
        true: {
          outlined: true,
          text: 'Salva',
          color: 'success',
          async handler () {
            const done = await vm.$http.post('/accounts/phone/', vm.newPhone)
              .then(r => {
                if (r.data.confirmed) {
                  vm.askCode = false
                  store.commit('account/updatePhone', r.data.phone)
                  messages.info('Numero aggiornato')
                  return true
                }
                if (r.data.token) {
                  vm.newPhone = r.data
                  vm.askCode = true
                  return false
                }
              })
              .catch(e => {
                messages.error(e)
                return false
              })
            return done
          }
        }
      }
    }
  },
  methods: {
    checkPhone (formattedNumber) {
      if (!formattedNumber) {
        this.phoneProps.valid = true
        return true
      }
      const phone = this.$refs?.phone.phoneObject || null
      if (phone) {
        this.phoneProps.valid = phone.isValid
        return phone.isValid || 'Numero non valido'
      }
    },
    updated (data) {

    }
  },
  // watch: {
  //   activePhone () {
  //     this.phoneList.forEach(e => { e.primary = e.phone === this.activePhone.phone })
  //   }
  // 3481696966
  // },
  mounted () {
    global.phone = this
    this.$http.get('/accounts/phone/')
      .then(r => {
        this.newPhone = r.data
        this.currentPhone = r.data
      })
  }
}
</script>
<style lang="scss">
.sso-tel-input {
  .country-code {
    display: none;
  }
}
</style>
