<template>
  <v-container
    class="fill-height main-view"
    fluid
  >
    <v-row
      align="center"
      justify="center"
    >
      <v-col
        cols="12"
        sm="8"
        md="4"
      >
        <v-card class="elevation-12 register-form">
          <account-toolbar title="Registrati su EpSso" />
          <v-form
            ref="form"
            v-model="valid"
          >
            <v-card-text>
              <p>
                Se hai già un account valido <router-link :to="{ name: 'Login', query: $route.query }">
                  Accedi
                </router-link>.
              </p>
              <v-card
                flat
              >
                <v-card-title class="text-subtitle-1">
                  Dati di registrazione
                </v-card-title>
                <v-card-text>
                  <v-text-field
                    v-model="register.username"
                    label="Username"
                    name="login"
                    prepend-icon="mdi-account"
                    tabindex="1"
                    :rules="[rules.required]"
                    type="text"
                  />
                  <v-text-field
                    v-model="register.email"
                    label="Email"
                    name="email"
                    tabindex="1"
                    prepend-icon="mdi-email"
                    :rules="[rules.required, rules.email]"
                    type="text"
                  />
                  <v-text-field
                    v-model="register.password1"
                    id="password"
                    label="Password"
                    name="password"
                    tabindex="1"
                    prepend-icon="mdi-lock"
                    :rules="[rules.required]"
                    :append-icon="show.password1 ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="show.password1 ? 'text' : 'password'"
                    counter
                    clearable
                    @click:append="show.password1 = !show.password1"
                  />
                  <v-text-field
                    v-model="register.password2"
                    id="password"
                    label="Verifica password"
                    name="password"
                    tabindex="1"
                    prepend-icon="mdi-lock"
                    :rules="[rules.required, rules.sameAs(register, 'password1', 'Password')]"
                    :append-icon="show.password2 ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="show.password2 ? 'text' : 'password'"
                    counter
                    clearable
                    @click:append="show.password2 = !show.password2"
                  />
                  <v-switch
                    v-model="register.auth_privacy"
                    class="text-caption font-weight-light"
                    label="Autorizzazione trattamento dati"
                    name="auth_privacy"
                    prepend-icon="mdi-book-information-variant"
                    type="text"
                  />
                </v-card-text>
              </v-card>
            </v-card-text>
            <v-card-text v-if="!hideAccounts">
              <l-register-info
                v-if="registerApp.regType === 'direct'"
                ref="regInfo"
                :reg-info="nextParams"
              />
              <l-app-selector
                v-else
                v-model="registerApp"
                mandatory
              />
            </v-card-text>
            <v-card-actions>
              <v-spacer />
              <v-btn
                color="primary"
                @click="doSignUp"
              >
                Registrati
              </v-btn>
            </v-card-actions>
          </v-form>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex'
import rules from '@/utils/rules.js'
import { paramsFromNext } from '@/utils/misc.js'
import AppSelector from '../Dashboard/AppSelector.vue'
import RegisterInfo from './RegisterInfo.vue'

export default {
  components: {
    'l-app-selector': AppSelector,
    'l-register-info': RegisterInfo,
    AccountToolbar: () => import('./AccountToolbar.vue')
  },
  data () {
    return {
      valid: false,
      rules,
      appRegItem: {
        regType: 'vcode',
        app: {
          id: null,
          name: 'register',
          description: null,
          logo: null
        },
        login: {
          vcode: null,
          username: null,
          password: null
        }
      },
      show: {
        password1: null,
        password2: null,
        app_password: null
      },
      register: {
        app_login: null,
        app_password: null,
        auth_privacy: true,
        email: null,
        password1: null,
        password2: null,
        username: null,
        validation_code: null
      }
    }
  },
  computed: {
    nextParams () {
      return paramsFromNext(this.$route.query)
    },
    hideAccounts () {
      return false
      // return this.$route.query.hideAccounts === true
    },
    registerApp: {
      get () {
        return this.appRegItem
      },
      set (app) {
        if (app && app.app && app.app.id) this.appRegItem = app
      }
    }
  },
  methods: {
    ...mapActions('account', ['registerUser']),
    doSignUp () {
      if (!this.$refs.form.validate()) return
      this.register.app_id = this.appRegItem.app.id
      this.register.app_login = this.appRegItem.login.username
      this.register.app_password = this.appRegItem.login.password
      this.register.validation_code = this.appRegItem.login.vcode
      this.register.inerith_accounts = this.nextParams.hideAccounts
      this.registerUser(this.register)
    }
  }
}
</script>
<style lang="scss">
.register-form {
  z-index: 10;
  .v-card__text, .v-card__title {
    margin-top: 0 !important;
    padding-top: 0 !important;
    margin-bottom: 0 !important;
    padding-bottom: 0 !important;
  }
}
</style>
